<template>
	<div>
		<div id="layout-wrapper">
			<header id="page-topbar">
				<div class="container-fluid">
					<div class="topnav">
						<nav class="navbar navbar-light navbar-expand-lg topnav-menu">
							<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content" aria-controls="topnav-menu-content" aria-expanded="false" aria-label="Toggle navigation">
								<span class="navbar-toggler-icon"></span>
							</button>
							<div class="collapse navbar-collapse" id="topnav-menu-content">
								<ul class="navbar-nav">
									<li class="nav-item dropdown" v-for="(item, index) in menu">
										<a class="nav-link dropdown-toggle arrow-none" href="#" role="button" @click="item.show = !item.show">
											<i class="uil-apps me-2"></i>{{item.title}} <div class="arrow-down"></div>
										</a>
										<div class="dropdown-menu" :class="{'show': item.show}">
											<router-link v-for="sitem in item.elems" :to="{path: '/lesson/'+item.section+'/'+sitem.lesson}" class="dropdown-item">{{sitem.title}}</router-link>
										</div>
									</li>
								</ul>
							</div>
						</nav>
					</div>
				</div>
			</header>
			<div class="main-content">
				<div class="page-content mt-0">
					<div class="container-fluid">
						<router-view/>
					</div>
				</div>
				<footer class="footer">
					<div class="container-fluid">
						<div class="row">
							<div class="col-sm-6">
								2022 © Lessons.
							</div>
							<div class="col-sm-6">
								<div class="text-sm-end d-none d-sm-block">
									Разработал <a href="https://bylex.info" target="_blank" class="text-reset">Алексей (lexinzector) Михалёв</a>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</div>
		</div>
	</div>
</template>

<style lang="css">

</style>

<script>
import { useMeta } from 'vue-meta';
//import Navbar from '@/components/Navbar.vue';

export default {
	name: 'LayoutMain',
	setup(){
		useMeta({title: 'Lessons'});
	},
	data: () => ({
		menu: [
			{
				section: 'html',
				title: 'HTML',
				elems: [
					{
						lesson: '1',
						title: 'Урок 1',
					},
					{
						lesson: '2',
						title: 'Урок 2',
					},
					{
						lesson: '3',
						title: 'Урок 3',
					},
				],
				show: false,
			},
			{
				section: 'css',
				title: 'CSS',
				elems: [
					{
						lesson: '1',
						title: 'Урок 1',
					},
				],
				show: false,
			},
			{
				section: 'bootstrap',
				title: 'Bootstrap',
				elems: [
					{
						lesson: '1',
						title: 'Урок 1',
					},
				],
				show: false,
			},
			{
				section: 'js',
				title: 'JavaScript',
				elems: [
					{
						lesson: '1',
						title: 'Урок 1',
					},
					{
						lesson: '2',
						title: 'Урок 2',
					},
					{
						lesson: '3',
						title: 'Урок 3',
					},
				],
				show: false,
			},
			{
				section: 'php',
				title: 'PHP',
				elems: [
					{
						lesson: '1',
						title: 'Урок 1',
					},
					{
						lesson: '2',
						title: 'Урок 2',
					},
				],
				show: false,
			},
			{
				section: 'sql',
				title: 'SQL',
				elems: [
					{
						lesson: '1',
						title: 'Урок 1',
					},
				],
				show: false,
			},
			{
				section: 'hg',
				title: 'Mercurial',
				elems: [
					{
						lesson: '1',
						title: 'Урок 1',
					},
				],
				show: false,
			},
			/*{
				section: 'bash',
				title: 'Bash',
				elems: [
					{
						lesson: '1',
						title: 'Урок 1',
					},
				],
				show: false,
			},*/
		],
	}),
	methods: {
		
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	computed: {},
	components: {
		//Navbar,
	},
}
</script>