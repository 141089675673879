import { createRouter, createWebHistory } from 'vue-router';

import lib from '@/lib';

import Layout from '@/layouts/Layout.vue';

import Main from '@/pages/Main.vue';
import Lesson from '@/pages/Lesson.vue';

import Test1 from '@/pages/Test1.vue';
import Test2 from '@/pages/Test2.vue';

import PageNotFound from '@/pages/PageNotFound.vue';

const routes = [
	/*{
		path: '/',
		redirect: '/index',
	},*/
	{
		path: '/',
		component: Layout,
		children: [
			{
				path: '/',
				component: Main,
			},
			{
				path: '/lesson/:section/:lesson',
				component: Lesson,
			},
			{
				path: '/test1',
				component: Test1,
			},
			{
				path: '/test2',
				component: Test2,
			},
		],
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'not-found',
		component: PageNotFound,
	},
	{
		path: '/:pathMatch(.*)',
		name: 'bad-not-found',
		component: PageNotFound,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

/*router.beforeEach((to, from, next) => {
	storeInstance.dispatch('checkAuth').then(() => {
		if(to.matched.some(record => record.meta.requiresAuth)){
			if(!storeInstance.getters.isLoggedIn){
				next({
					path: '/login',
					params: {
						nextUrl: to.fullPath,
					},
				})
			} else {
				let user = lib.localStorageGet('user')||{};
				if(to.matched.some(record => record.meta.is_admin)){
					if(user.is_admin){
						next()
					} else {
						next('/')
					}
				} else {
					next()
				}
			}
		} else if(to.matched.some(record => record.meta.guest)){
			if(!storeInstance.getters.isLoggedIn){
				next()
			} else {
				next('/')
			}
		} else {
			next() 
		}
	});
});*/
/*router.beforeEach((to, from, next) => {
	if (to.path=='/login' && storeInstance.state.auth.userProfile!==null) next({ path: '/' })
  	else next()
})*/

export default router;