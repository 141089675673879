<template>
	<div>
		<div class="row">
			<div class="col-12">
				<div class="page-title-box d-flex align-items-center justify-content-between">
					<h4 class="mb-0">{{selected_section}}. Урок {{selected_lesson}}</h4>
					<div class="page-title-right">
						<ol class="breadcrumb m-0">
							<li class="breadcrumb-item">
								<router-link :to="{path: '/'}">Главная</router-link>
							</li>
							<li class="breadcrumb-item active">{{selected_section}}. Урок {{selected_lesson}}</li>
						</ol>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card" v-for="elem in selected_data">
					<div class="card-body">
						<h4 class="card-title">{{elem.title}}</h4>
						<div v-if="elem.type == 'theory'">
							<div class="text-center">
								<!--<div>{{elem.file}}</div>-->
								<a v-if="elem.file" :href="elem.file" download class="btn btn-primary waves-effect waves-light mt-3">Скачать презентацию</a>
							</div>
						</div>
						<div v-if="elem.type == 'practic'">
							<div class="row">
								<div class="col-lg-6" v-if="elem.materials_file">
									<div class="d-grid gap-2">
										<a :href="elem.materials_file" download class="btn btn-primary waves-effect waves-light">Материалы для выполнения заданий</a>
									</div>
								</div>
								<div class="col-lg-6" v-if="elem.src_file">
									<div class="d-grid gap-2">
										<a :href="elem.src_file" download class="btn btn-secondary waves-effect waves-light">Исходники (выполненный вариант)</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import { useMeta } from 'vue-meta';

export default {
	name: 'Lesson',
	setup(){
		useMeta({title: 'Урок | Lessons'});
	},
	data: () => ({
		list: {
			html: {
				title: 'HTML',
				data: {
					1: [
						{
							title: 'Теория',
							type: 'theory',
							file: '/files/html-theory-1.pptx',
						},
						{
							title: 'Практика',
							type: 'practic',
							materials_file: '/files/html-materials-1.zip',
							src_file: '/files/html-src-1.zip',
						},
					],
					2: [
						{
							title: 'Теория',
							type: 'theory',
							file: '/files/html-theory-2.pptx',
						},
						{
							title: 'Практика',
							type: 'practic',
							materials_file: '/files/html-materials-2.zip',
							src_file: '/files/html-src-2.zip',
						},
					],
					3: [
						{
							title: 'Теория',
							type: 'theory',
							file: '/files/html-theory-3.pptx',
						},
						{
							title: 'Практика',
							type: 'practic',
							materials_file: '/files/html-materials-3.zip',
							src_file: '/files/html-src-3.zip',
						},
					],
				},
			},
			css: {
				title: 'CSS',
				data: {
					1: [
						{
							title: 'Теория',
							type: 'theory',
							file: '/files/css-theory-1.pptx',
						},
						{
							title: 'Практика',
							type: 'practic',
							materials_file: '/files/css-materials-1.zip',
							src_file: '/files/css-src-1.zip',
						},
					],
				},
			},
			bootstrap: {
				title: 'Bootstrap',
				data: {
					1: [
						{
							title: 'Теория',
							type: 'theory',
							file: '/files/bootstrap-theory-1.pptx',
						},
						{
							title: 'Практика',
							type: 'practic',
							materials_file: '/files/bootstrap-materials-1.zip',
							src_file: '/files/bootstrap-src-1.zip',
						},
					],
				},
			},
			js: {
				title: 'JavaScript',
				data: {
					1: [
						{
							title: 'Теория',
							type: 'theory',
							file: '/files/js-theory-1.pptx',
						},
						{
							title: 'Практика',
							type: 'practic',
							materials_file: '',
							src_file: '/files/js-src-1.zip',
						},
					],
					2: [
						{
							title: 'Теория',
							type: 'theory',
							file: '/files/js-theory-2.pptx',
						},
						{
							title: 'Практика',
							type: 'practic',
							materials_file: '/files/js-materials-2.zip',
							src_file: '/files/js-src-2.zip',
						},
					],
					3: [
						{
							title: 'Теория',
							type: 'theory',
							file: '/files/js-theory-3.pptx',
						},
						{
							title: 'Практика',
							type: 'practic',
							materials_file: '/files/js-materials-3.zip',
							src_file: '/files/js-src-3.zip',
						},
					],
				},
			},
			php: {
				title: 'PHP',
				data: {
					1: [
						{
							title: 'Теория',
							type: 'theory',
							file: '/files/php-theory-1.pptx',
						},
						{
							title: 'Практика',
							type: 'practic',
							materials_file: '',
							src_file: '/files/php-src-1.zip',
						},
					],
					2: [
						{
							title: 'Теория',
							type: 'theory',
							file: '/files/php-theory-2.pptx',
						},
						{
							title: 'Практика',
							type: 'practic',
							materials_file: '/files/php-materials-2.zip',
							src_file: '/files/php-src-2.zip',
						},
					],
				},
			},
			sql: {
				title: 'SQL',
				data: {
					1: [
						{
							title: 'Теория',
							type: 'theory',
							file: '/files/sql-theory-1.pptx',
						},
						{
							title: 'Практика',
							type: 'practic',
							materials_file: '/files/sql-materials-1.zip',
							src_file: '/files/sql-src-1.zip',
						},
					],
				},
			},
			hg: {
				title: 'Mercurial',
				data: {
					1: [
						{
							title: 'Теория',
							type: 'theory',
							file: '/files/hg-theory-1.pptx',
						},
						{
							title: 'Практика',
							type: 'practic',
							materials_file: '/files/hg-materials-1.zip',
							src_file: '/files/hg-src-1.zip',
						},
					],
				},
			},
			/*bash: {
				title: 'Bash',
				data: {
					1: [
						{
							title: 'Теория',
							type: 'theory',
							file: '/files/bash-theory-1.pptx',
						},
						{
							title: 'Практика',
							type: 'practic',
							materials_file: '/files/bash-materials-1.zip',
							src_file: '/files/bash-src-1.zip',
						},
					],
				},
			},*/
		},
		selected_section: '?',
		selected_lesson: '?',
		selected_data: 'Урок не выбран',
	}),
	methods: {
		loadData(section, lesson){
			if(typeof this.list[section] !== 'undefined'){
				this.selected_section = this.list[section].title;
				if(typeof this.list[section].data[lesson] !== 'undefined'){
					this.selected_lesson = lesson;
					this.selected_data = this.list[section].data[lesson];
				} else {
					this.$router.push({name: 'not-found'});
				}
			} else {
				this.$router.push({name: 'not-found'});
			}
		},
	},
	beforeMount(){
		window.scrollTo(0, 0);
		this.loadData(this.$route.params.section, this.$route.params.lesson);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
		this.loadData(to.params.section, to.params.lesson);
	},
	computed: {},
	components: {
		//Navbar,
	},
};
</script>